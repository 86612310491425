import React, { Fragment, useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { differenceInYears } from 'date-fns'
import Cookies from 'universal-cookie'

import { Form, Field, useField, useForm } from 'react-final-form'

import './AgeGate.css'
import './App.css'

import { ModalContext } from '@pokemon/design.ui.containers.base-modal'
import { Modal } from '@pokemon/design.ui.containers.modal'
import { DropdownMenu } from '@pokemon/design.ui.input.dropdown-menu'
import { BasicButton } from '@pokemon/design.ui.input.basic-button'
import { DatePicker } from '@pokemon/design.ui.input.date-picker'

import { screens } from './App'
import { useCountries } from './Countries'
import FormValidators from './FormValidators'

import { pokemonDOBFormatter } from './util'
import { InputErrorMessage } from './InputErrorMessage'
import { datadogRum } from '@datadog/browser-rum'

const initialDate = {}

const months = (t) => [
  t('month_january', 'January'),
  t('month_february', 'February'),
  t('month_march', 'March'),
  t('month_april', 'April'),
  t('month_may', 'May'),
  t('month_june', 'June'),
  t('month_july', 'July'),
  t('month_august', 'August'),
  t('month_september', 'September'),
  t('month_october', 'October'),
  t('month_november', 'November'),
  t('month_december', 'December')
]

export default function AgeGate (props) {
  const { t } = useTranslation()

  const { openModal } = useContext(ModalContext)

  const cookies = new Cookies()

  const onSubmit = (values) => {
    const country = values.country
    const result = countries.find(({ label }) => label === country)

    const valuesToSubmit = {}

    // we don't want to pass on extra metadata for the country, so let's process it down to just the value (iso2 code)
    valuesToSubmit.country_code = result.value

    const today = new Date()
    const birthDate = new Date(values.dateOfBirth.year, parseInt(values.dateOfBirth.month) - 1, values.dateOfBirth.day)

    // let's process our date of birth from a JS object to a string like YYYY-MM-DD
    valuesToSubmit.date_of_birth = pokemonDOBFormatter(birthDate)

    const age = differenceInYears(today, birthDate)

    datadogRum.addAction('confirm country and age', { countryCode: valuesToSubmit.country_code, age })

    if (age >= result.consent_age) {
      cookies.set('country', result.value)
      cookies.set('dob', birthDate.getTime())
      props.changeScreen(screens.ENTER_EMAIL)
      props.handleFormSubmit(valuesToSubmit)
    } else {
      const cookieOptions = { maxAge: 2 * 60 * 60 }
      cookies.set('failed_age_gate', 'true', cookieOptions)
      props.changeScreen(screens.ENTER_PARENT_EMAIL)
    }
  }

  const countryRequired = (value) => value ? undefined : t('error_country_missing', 'You must select a Country/Region')

  const countries = useCountries()
  const countryNames = []
  Object.entries(countries).forEach(([key, value]) => {
    countryNames.push(value.label)
  })

  return (
    <Form
      onSubmit={onSubmit}
      render={
        ({ handleSubmit, submitting, valid }) => (
          <form className="age-gate-form" onSubmit={handleSubmit}>
            <h1 className = 'header'>{t('creation_header', 'Enter your country or region and your birthdate')}</h1>
            <p className = 'secondary'>{t('enter_starting_info', 'Please enter accurate information or you may be unable to recover your account should you forget your username or password.')}</p>
            <div className='country_select'>
              <label className="box-label" htmlFor="country-select">{t('country_region', 'Country/Region')}
              </label>
              <Field
                name="country"
                validate={countryRequired}
                render={
                  ({ input, meta }) => (
                    <Fragment>
                      <DropdownMenu options={countryNames}
                        name={input.name}
                        onChange={input.onChange}
                        onFocus={input.onFocus}
                        onBlur={input.onBlur}
                        id="country-select"
                      />
                      {meta.error && meta.touched && <InputErrorMessage>{meta.error}</InputErrorMessage>}
                    </Fragment>
                  )
                }
              />
            </div>
            <Field
              name="dateOfBirth"
              initialValue={initialDate}
              validate={FormValidators.validateBirthDate(t)}
              render={
                ({ input, meta }) => (
                  <Fragment>
                    <DatePicker
                      monthLabel={t('month', 'Month')}
                      dayLabel={t('day', 'Day')}
                      yearLabel={t('year', 'Year')}
                      months={months(t)}
                      onChange={input.onChange}
                      onFocus={input.onFocus}
                      onBlur={input.onBlur}
                      value={input.value}
                      name={input.name}
                      />
                    {meta.error && meta.touched && <InputErrorMessage>{meta.error}</InputErrorMessage>}
                  </Fragment>
                )
              }
            />
            <BasicButton
              id="ageGateSubmit"
              disabled={submitting || !valid}
              style={{ marginTop: 30 }}
              onClick={() => {
                openModal('ageConfirm')
              }}
            >
              {t('continue_button', 'Continue')}
            </BasicButton>
            <AgeGateModal />
          </form>
        )
      }
    />
  )
}

const AgeGateModal = (props) => {
  const { input: { value: dateOfBirth } } = useField('dateOfBirth')
  const { input: { value: country } } = useField('country')
  const { submit } = useForm()

  const { closeModal } = useContext(ModalContext)
  const { t } = useTranslation()

  return <Modal
            name="ageConfirm"
            primaryOnClick={() => {
              submit()
              closeModal('ageConfirm')
            }}
            title={t('age_gate_confirm_title', 'Everything Correct?')}
            primaryText={t('age_gate_confirm', 'I Am Sure')}
            closeText={t('age_gate_cancel', 'Go Back')}
            contentBoxProps={{
              Logo: null
            }}
          >
            <div className='modal-contents'>
              <Trans
                t={t}
                i18nKey='age_gate_confirm_details'
                defaults='Please double-check that you have accurately entered your <strong>country/region</strong> and <strong>birthdate</strong> as you may be unable to recover your account should you forget your username or password.'
                components={{ strong: <strong /> }}
              />
              <div className='grey-callout-box' style={{ marginTop: '10px', marginBottom: '30px' }}>
                <p>{country}</p>
                <p>
                  <Trans
                    t={t}
                    i18nKey='date_format'
                    defaults='{{month}} {{day}}, {{year}}'
                    values={{
                      year: dateOfBirth.year,
                      month: months(t)[dateOfBirth.month - 1],
                      day: dateOfBirth.day
                    }}
                  />
                </p>
              </div>
            </div>
          </Modal>
}
